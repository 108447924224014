
    .paycard{
        border: 1px solid rgba(0, 0, 0, 0.082);
        border-radius: 10px;
        height: auto;
        h1{
            margin: 1rem 2rem 0 2rem;
            font-weight: 700;
        }
        .desc{
            margin: 1rem 2rem;
            color: rgba(0, 0, 0, 0.445);
        }
        .devider{
            width: 90%;
            margin: 0 auto;
            background-color: rgb(0, 0, 0);
        }
        .flex-info{
            display: flex;
            justify-content: space-between;
            margin-left: 2rem;
            margin-right: 2rem;
            .flex-left{
                align-items: center;
                margin-bottom: 2rem;
                .info{
                    display: flex;
                    justify-content: left;
                    margin-left: 0;
                    margin-top: 1rem;
                    p{
                        font-weight: 600;
                    }
                    span{
                        color: #15803d;
                        margin-left: 3rem;
                    }
                    .top-span{
                        margin-left: 4rem;
                    }
                }
            }
            h2{
                margin-top: 4rem;
                color: rgba(87, 87, 87, 0.856);
            }
        }
    }
.pay-info{
    margin-top: 1rem;
    position: relative;
    width: 100%;
    .input-flex{
        display: flex;
        width: 90%;
        justify-content: space-between;
        margin: 1rem 2rem;
      .flex-input{
        width: 49%;
        input{
            width: 100%;
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.171);
        }
      }
    }
    .full-length{
        width: 90%;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.171);
        margin-left: 2rem;
        // margin-bottom: 1rem;
    }
    .img-flex{
        display: flex;
        margin: 1rem 2rem;
        img{
            height: 5rem;
            width: 10rem;
            border-radius: 5px;
        }
        .pad-right{
            margin-left: 2rem;
        }
    }
    .pay{
        margin-bottom: 1rem;
    }
    .amount{
        width: 10rem;
        border-radius: 5px;
        text-align: center;
        margin-bottom: 1rem;
        margin-left: 2rem;
        border: 1px solid rgba(0, 0, 0, 0.171);
    }
    .btn-flex{
        display: flex;
        justify-content: right;
        margin-right: 2rem;
        
       .btn{
        background-color: #15803d;
        color: white;
        text-transform: capitalize;
        width: 15rem;
        border-radius: 50px;
        margin-bottom: 2rem;
        margin-top: 1rem;
       }
    }
}
.over-flow-box{
    position: absolute;
      top: 50%;
      left: 75%;
      transform: translate(-50%, -50%);
      width: 50%;
      background-color: white;
      box-shadow: 24;
      padding: 4;
      overflow-y: auto;
      height: 100vh;
}

@media (max-width: 665px){
    .over-flow-box{
        position: relative;
        left: 50%;
        width: 100%;
    }
}

.close-modal{
    cursor: pointer;
    position: absolute;
    left: 95%;
    top: 1rem;
}

.newAddress{
    border: 1px solid rgba(0, 0, 0, 0.055);
    color: #15803d;
    margin-left: 2rem;
    margin-top: 1rem;
    height: 2.5rem;
    width: 15rem;
    border-radius: 5px;
}

.new2{
    margin-top: 0;
}


.status-flex{
    width: 80%;
    margin: 1rem auto;
    display: flex;
    align-items: center;
}

.top-cards{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.flex-body{
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    .box-flex{
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        margin-top: 1rem;
    }
    .body-cards{
        width: 70%;
        .top-flex{
            display: flex;
            justify-content: space-between;
            width: 95%;
            margin: 1rem  auto;
            .shopflex{
                display: flex;
                align-items: center;
                h1{
                    margin-left: 1rem;
                }
            }
        }
    }
    .right-bar{
        width: 29%;
        img{
            height: 100px;
            width: 100px;
            border-radius: 50%;
            margin-top: 1rem;
            margin-left: 1rem;
        }
        h1{
            margin-left: 1rem;
            margin-top: 1rem;
            span{
                color: #15803d;
            }
        }
    }
}
.status-icons{
    color: #15803d;
    font-size: 20px;
}
.open{
    cursor: pointer;
}
.grey-text{
    color: rgba(0, 0, 0, 0.253);
}


    .flex-top{
        display: flex;
        justify-content: space-between;
        h2{
            span{
                color: #15803d;
            }
        }
        .left{
            h2{
                margin-top: 1rem;
            }
            p{
                display: flex;
                align-items: center;
                margin-top: 1rem;
            }
        }
        .right{
            p{
                background-color: #2b34e831;
                border-radius: 50px;
                padding: .5rem 2rem;
                color: #2B33E8;
            }
            .canceled{
                background-color: #e82b2b31;
                border-radius: 50px;
                padding: .5rem 2rem;
                color: #e82b2b;
            }
            .complete{
                background-color: #2bd1685c;
                border-radius: 50px;
                padding: .5rem 2rem;
                color: #15803d;
            }
        }
    }
    .bottom-card{
        margin-top: 2rem;
        .flex-img{
            margin: 1rem auto;
            width: 95%;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            img{
                height: 100px;
                width: 130px;
                border-radius: 5px;
            }
            .price{
                color: #15803d;
            }
        }

        .bottom-btn{
            background-color: red;
            color: white;
            margin-left: 84.5%;
            margin-bottom: 1rem;
            width: 6rem;
        }
        .bottom-btn:hover{
            background-color: red;
            color: white;
            margin-left: 84.5%;
            margin-bottom: 1rem;
            width: 6rem;
        }
    }

   .blogs-c{
    display: flex;
    margin: 0rem .7rem;
    .blog-card{
        height: auto;
        width: 21rem;
        border-radius: 0;
        box-shadow: none;
        border: none;
        margin-bottom: 1rem;
        img{
            width: 100%;
            height: 15rem;
        }
        .author{
            margin-top: 1rem;
            .point{
                font-size: 8px;
            }
        }
        .blog-title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            // margin: 1rem 0;
            .title-text{
                font-size: 20px;
                font-weight: 600;
            }
            .more{
                cursor: pointer;
            }
        }
        .blog-desc{
            color: rgba(0, 0, 0, 0.299);
        }
    }
   }