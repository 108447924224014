.main {
  width: 100vw;
  margin-top: 9rem;
  display: flex;
  position: relative;
  overflow-x: hidden;
  justify-content: space-between;
  overflow-x: hidden;
}

.cards {
  height: auto;
  width: 15rem;
  border: 1px solid rgb(236, 236, 236);
  border-radius: 5px;
  margin: 0.5rem 0.5rem 0.5rem 1rem;
  .img {
    height: 25vh;
    width: 100%;
    margin: 0 auto;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  h2 {
    margin-left: 0.3rem;
    margin-top: 0.3rem;
  }
  h4 {
    color: #15803d;
    margin-left: 0.3rem;
    margin-top: 0.3rem;
  }
  p {
    margin-top: 0.5rem;
    color: rgb(102, 102, 102);
    margin-left: 0.3rem;
    margin-bottom: 1rem;
  }
  .button-amount {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    margin-bottom: 1rem;

    .qty {
      display: flex;
      align-items: center;
      // margin-right: 0.3rem;
      color: #208143;
      width: 120px;
      height: 30px;
      justify-content: space-evenly;
      padding: 2px;
      border-radius: 5px;
    }
  }
}

.detail-main {
  width: 95%;
  margin: 9rem auto 0 auto;
  height: auto;
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-wrap: wrap;
  .img-container {
    width: 30%;
    flex-grow: 0.2;
    height: auto;
    margin-top: 1rem;
    img {
      border-radius: 5px;
      width: 100%;
      height: 50vh;
    }
    .bottom-img {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      img {
        width: 23%;
        height: 18vh;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
  .detail-container {
    width: 60%;
    margin-top: 1rem;
    flex-grow: 0.2;
    height: auto;
    margin-left:5rem;
    border: 1px solid rgba(0, 0, 0, 0.048);
    border-radius: 10px;
    h1 {
      font-weight: 500;
      margin-top: 1rem;
      margin-left: 2rem;
    }
    .grey {
      color: rgb(146, 146, 146);
      margin: 2rem;
    }
    .flex-info {
      display: flex;
      justify-content: space-between;
      .detail {
        .inner {
          margin-left: 7rem;
          color: #15803d;
        }
        .place {
          color: rgb(141, 141, 141);
        }
      }
      .buttons {
        display: flex;
        flex-direction: column;
        .action-btn {
          width: 13rem;
          background-color: #15803d;
          margin-top: 1rem;
          margin-right: 2rem;
          color: white;
          font-size: 15px;
          height: 3rem;
          text-transform: capitalize;
          border-radius: 50px;
        }
        .transperent {
          background-color: white;
          color: #15803d;
          border: 1px solid;
          border-color: #15803d;
        }
        .inner-flex {
          display: flex;
          justify-content: space-between;
          font-size: 20px;
          .icons {
            font-size: 25px;
          }
        }
      }
    }
    .arrow-left {
      margin-top: 2rem;
      margin-left: 89%;
      font-size: 30px;
      cursor: pointer;
    }
  }
  .payment-methods {
    width: 100%;
    margin: 0 auto;
    h1 {
      display: block;
      text-align: right;
      margin-right: 29rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .gateways {
      display: flex;
      justify-content: right;
      width: 100%;
      // margin-left: 50%;
      .img {
        display: flex;
        justify-content: space-between;
        img {
          height: 8rem;
          width: 19rem;
          border-radius: 5px;
        }
        .left {
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.cart-main {
  margin-top: 10rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .left {
    width: 35rem;
    flex-grow: 0.5;
    margin-left: 0rem;
  }

  .right {
    width: 40rem;
    margin-left: 1rem;
    flex-grow: 0.5;
    // background-color: red;
    margin-top: 1rem;
    .right-main {
      width: 99%;
      margin: 0 auto;
      border: 1px solid rgba(0, 0, 0, 0.075);
      border-radius: 10px;
      .top {
        display: flex;
        justify-content: space-between;
        margin: 1rem 2rem;
        p {
          color: #15803d;
        }
      }
      .check-out {
        margin-left: 58%;
        background-color: #15803d;
        color: white;
        width: 15rem;
        height: 3rem;
        border-radius: 50px;
        margin-top: 1rem;
      }
      .icons {
        margin-top: 2rem;
        margin-bottom: 1rem;
        font-size: 30px;
        margin-left: 88%;
        cursor: pointer;
      }
    }
  }
}

.left-main {
  width: 100%;
  height: 20vh;
  border: 1px solid rgb(241, 241, 241);
  border-radius: 10px;
  margin-left: 1rem;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  img {
    height: 18vh;
    margin-top: 0.3rem;
    margin-left: 0.2rem;
    border-radius: 10px;
    width: 10rem;
  }
  .info {
    width: 70%;
    margin: 0.3rem auto;
    display: flex;
    flex-direction: column;
    .info-top {
      display: flex;
      justify-content: space-between;
      h1 {
        font-weight: 700;
      }
      h4 {
        color: rgb(143, 143, 143);
      }
      .icon {
        cursor: pointer;
      }
    }

    .info-bottom {
      display: flex;
      justify-content: space-between;
      margin-top: 3rem;
      .lables {
        display: flex;
        p {
          color: #208143;
          font-weight: 600;
        }
      }
    }
  }
}

.success-main {
  margin-top: 5rem;
  width: 100%;
  height: 100vh;
  .inner {
    width: 50%;
    margin: 0 auto;
    img {
      width: 35rem;
      height: 35rem;
    }
  }
}

.all-products {
  margin-top: -4rem;
  .filters {
    width: 97%;
    margin: 2rem auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .filter {
      border-radius: 50px;
      width: 15rem;
      margin-bottom: 1rem;
      border: none;
      outline-color: #15803d;
      background-color: #00000010;
      // box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      //   rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    }

    .filter:focus {
      outline-width: 0;
      border: none;
    }
  }
  .inner-flex1 {
    h1 {
      margin: 1rem;
    }
    .products {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.inner-cards {
  .card-flex {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin: 0 auto;
  }
  .cat-flex1 {
    display: flex;
    align-items: center;
    margin: 1rem 0;
    h1 {
      font-size: 17px;
      font-weight: 600;
      margin-left: 1rem;
    }
    span {
      margin-left: 0.5rem;
    }
    .span:hover {
      cursor: pointer;
      color: #208143;
    }
    .see-more {
      margin-left: 0.5rem;
      margin-right: 0.5;
    }
  }
}

.main-body {
  margin-top: -4rem;
  .sliders {
    display: flex;
    height: 300px;
    width: 98%;
    margin: 0 auto;
    align-items: center;
    // justify-content: space-between;
    background-color: #15803c9c;
    .imgs {
      width: 400px;
      height: 250px;
      border-radius: 20px;
      margin-left: 2rem;
    }
    p {
      color: white;
      width: 30rem;
      text-align: left;

      margin-right: 2rem;
      button {
        display: flex;
        width: 15rem;
        align-items: center;
        justify-content: space-around;
        border: 1px solid white;
        height: 3rem;
        margin-top: 3rem;
        text-align: center;
      }
    }
  }
}

.icons {
  position: absolute;
  // color: white;
  background-color: white;
  top: 50%;
  font-size: 25px;
  border: 2px solid white;
  border-radius: 50%;
  cursor: pointer;
}
.prev {
  margin-left: 1%;
  z-index: 1;
}
.next {
  margin-left: 96.5%;
}

.explore {
  margin: 3rem 1rem;
  font-size: 25px;
  font-weight: 600;
}
.category {
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  .item {
    margin: 0 3rem;
    border-radius: 50px;
    img {
      height: 125px;
      width: 125px;
      border-radius: 100%;
    }
    h2 {
      text-align: center;
      margin-top: 0.5rem;
    }
  }
  .item:hover {
    cursor: pointer;
    img {
      border: 2px solid rgb(216, 216, 216);
    }
    h2 {
      text-decoration: underline;
      color: #208143;
    }
  }
}

.empty-cart {
  position: absolute;
  left: 35%;
  top: 50%;
  h1 {
    // font-weight: 8;
    font-size: 50px;
    color: rgba(0, 0, 0, 0.507);
  }
}

@media (max-width: 900px) {
  .payment-methods {
    h1 {
      width: 100%;
      margin-left: -63%;
    }
  }
  .detail-main {
    display: flex;
    flex-direction: column;
    .img-container {
      width: 80%;
      margin: 0 auto;
    }
    .detail-container {
      width: 80%;
      margin: 0 auto;
    }
    .gateways {
      width: 80%;
      margin: 0 auto;
      .img {
        width: 80%;
        margin: 0 auto;
      }
    }
  }
}

.message {
  ::-webkit-scrollbar {
    width: 0;
  }
  height: 75vh;
  width: 20rem;
  margin-top: -33rem;
  -moz-margin-top: -30rem;
  -webkit-margin-top: -33rem;
  margin-left: -18rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  cursor: default;
  position: relative;
  .mss-div {
    height: 55vh;
    margin-top: 4rem;
    overflow-y: auto;
    width: 100%;
    overflow-x: hidden;
    -ms-scollbar-width: none;
    .left{
      display: flex;
      justify-content: left;
      .response {
        margin: 0.1rem 1rem;
        max-width: 15rem;
        background-color: rgba(165, 165, 165, 0.123);
        padding: 0.5rem;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        scrollbar-width: none;
        overflow-x: hidden;
        overflow-wrap: break-word;
      }
    }
   .riht{
    display: flex;
    justify-content: right;
    .user {
      margin: 0.1rem 0.5rem;
      max-width: 15rem;
      background-color: #15803d;
      z-index: 0;
      color: white;
      padding: 0.5rem;
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px;
      border-bottom-right-radius: 20px;
      overflow-x: hidden;
      overflow-wrap: break-word;
    }
   }
  }
  .inputs {
    position: fixed;
    background-color: #15803d;
    height: 4rem;
    width: 20rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: .5rem;
    margin-left: auto;
    z-index: 5;
    .camera {
      color: white;
      font-size: 30px;
      margin-left: 0.2rem;
      cursor: pointer;
    }
    .telIcon {
      color: white;
      font-size: 30px;
      z-index: 5;
      margin-right: 0.2rem;
      cursor: pointer;
    }
  }
  .input1{
    position: fixed;
    background-color: #15803d;
    height: 4rem;
    width: 20rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    z-index: 5;
    .online{
      display: flex;
      margin-left: .5rem;
      align-items: center;
      p{
        color:white;
        margin-left: .5rem;
        text-align: left;
      }
    }
    .user-name{
      color: white;
      margin-right: .5rem;
    }
  }
}

.blog{
  width: 80%;
  margin: 9rem auto;
  height: auto;
  .over-lay{
    height: 25rem;
    position: relative;
    width: 98%;
    margin: 3rem auto;
    box-shadow: none;
    border-radius: 0;
    img{
      z-index: 0;
      position: absolute;
    }
    .ab-div{
      position: absolute;
      height: 25rem;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.508);
      z-index: 2;
      .author{
        color: white;
        margin-left: 1rem;
        margin-top: 1rem;
        .point{
          font-size: 10px;
        }
      }
      .blog-title{
        margin-left: 1rem;
        color: white;
        margin-top: 1rem;
      }
      .blog-desc{
        margin-left: 1rem;
        color: white;
        margin-top: 1rem;
      }
      .chip-con{
        display: flex;
        flex-direction: row;
        color: white;
        margin-top: 1rem;
        .chip{
          max-width: 10rem;
          margin: 0 1rem;
          color: white;
          border-color: white;
        }
      }
    }
  }
  .top-text{
    text-align: center;
  }
  .bolder{
    font-size: 30px;
    font-weight: 600;
    margin-top: 1rem;
  }
  .top{
    margin-top: 1rem;
  }
  .new-blog{
    // background-color: red;
    display: flex;
    flex-wrap: wrap;
  }
}