@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow-x: hidden;
}

.nav-style {
  z-index: 1000;
  background-color: white;
}


.add-btn {
  display: flex;
  background-color: #208143;
  align-items: center;
  margin-left: 0.3rem;
  color: white;
  width: 70px;
  height: 30px;
  justify-content: center;
  text-align: center;
  padding: 2px;
  border-radius: 5px;
}

.ico{
  margin: 1rem 0 2rem 89%;
  font-size: 25px;
}

option{
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.164);
  border-radius: 5px;
}

option:hover{
  background-color: red;
  border: 1px solid rgba(0, 0, 0, 0.164);
  border-radius: 5px;
}
.relative-nav{
  position: relative;
}
.icon-cart{
  font-size: 20px;
  font-weight: 800;
}

.local-length{
  background-color: #208143;
  border-radius: 50%;
  color: white;
  position: absolute;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -.5rem;
  left: .4rem;
}

.loadingButton{
  cursor: not-allowed;
  background-color: #20814450;
}


.newAddrress{
  background-color: red;
}

.img-ms{
  height: 100px;
  width: 100px;
  display: block;
  margin-top: .8rem;
  margin-bottom: .8rem;
  border-radius: 8px;
  margin: 1rem auto 0 0;
}
.img-mr{
  height: 100px;
  width:100px;
  display: block;
  margin-top: .8rem;
  margin-bottom: .8rem;
  border-radius: 8px;
   /* margin: 1rem auto 0 0; */
}

#wave {
  width: 40%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}


#wave .srtyping {
  border: 2px solid #208143;
  background: #6600ff03;
  padding: 10px 18px;
  border-radius: 50px;
  text-align: left;
  width: 100%;
  max-width: 250px;
  display: block;
  min-height: 22px;
}
#wave .srfriendzone {
  margin: 0 5px 0 0px;
}
#wave .srsend {
  float: right;
  cursor: pointer;
}
#wave .dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 0.5px;
  background: #208143;
  animation: wave 1s linear infinite;
  animation-delay: -0.9s;
}
#wave .dot.two {
  animation-delay: -0.7s;
}
#wave .dot:nth-child(3) {
  animation-delay: -0.6s;
}

@keyframes wave {
  0%,
	60%,
	100% {
    transform: initial;
  }
  30% {
    transform: translateY(-15px);
  }
}
