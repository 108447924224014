.register{
    height: 100vh;
    width: 100%;
    margin-top: 9rem;
    display: flex;
    justify-content: space-between;
    .left{
        width: 30%;
        background-color: #20814410;
        height: 100vh;
     .flex-msg{
        display: flex;
        align-items: center;
        margin-top: 2rem;
        margin-left: 2rem;
        h2{
            margin-left: 2rem;
        }
        .tick{
            color:  #208143;
            border: 1px solid  #208143;
            border-radius: 50%;
        }
     }
    }
    .right{
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2rem;
        height: auto;
        .scan{
            background-color: #ffffff;
            height: 30px;
            width: 30px;
            border-radius: 5px;
            font-size: 10px;
            color: #c0c0c0;
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
        }
        h1{
            font-weight: 800;
            margin-top: 1.2rem;
        }
        h3{
            font-size: 15px;
            margin-top: .5rem;
            color: #838383;
        }
        img{
            height: 120px;
            width: 120px;
            color: #208143;
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
            border-radius: 5px;
            margin-top: 1.2rem;
        }
        .flex-text{
            display: flex;
            align-items: center;
            margin: 1.5rem auto;
            h4{
                margin: 0 .5rem;
                color: #5a5a5a;
            }
        }

        .input {
            height: 40px;
            border: 1px solid  rgb(224, 224, 224);
            border-radius: 5px;
            width: 40%;
            margin-top: 2rem;
            outline: none;
          }
    
        .input:focus{
           outline-color: #208143;
        }
    
    
          .PhoneInputInput {
            height: 40px;
            border: 1px solid  rgb(224, 224, 224);
            border-radius: 5px;
            width: 100%;
          }
    
        //   .PhoneInputInput:focus {
        //     outline-color: #208143;
        //     border: none;
        //   }
    
          .PhoneInputCountry {
            height: 40px;
            display: flex;
            justify-content: center;
            border: 1px solid  rgb(224, 224, 224);
            border-radius: 5px;
            width: 100px;
          }

        
    
          .PhoneInputCountrySelect {
            height: 40px;
            display: flex;
            justify-content: center;
            // border: 1px solid  rgb(224, 224, 224);
            border-radius: 5px;
            width: 100px;
          }

          .PhoneInputCountryIcon--borde {
            border: none;
           }

          .PhoneInputCountryIconImg{
            height: 14px;
            display: flex;
            justify-content: center;
            // border: 1px solid  rgb(224, 224, 224);
            width: 100px;
            margin-top: 1.1px;
            margin-left: 1px;
          }
            
          .input::placeholder {
            margin-left: 2rem;
            padding-left: 1rem;
            text-transform: capitalize;
          }

          .register-btn{
            background-color: #208143;
            color: white;
            text-transform: capitalize;
            width: 40%;
            margin-top: 2rem;
          }

          .loading{
            cursor:not-allowed;
            background-color: #20814434;
          }

          p{
            margin-top: 1rem;
            button{
                color: #208143;
            }
          }
    }
}

.otp-modal{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .dis-msg{
    // background-color: red;
    span{
      color: #208143;
      display: block;
    }
  }
  .otp-input{
    width: 80%;
    margin-top: 3rem;
    margin: 1px solid rgb(247, 247, 247);
    border-radius: 5px;
    outline: none;
  }
  .confirm{
    background-color: #208143;
    color: #ffffff;
    width: 80%;
    margin-top: 2rem;
    height: 2.5rem;
    border-radius: 5px;
    text-transform: capitalize;
  }
  .confirm:hover{
    background-color: #208143;
    color: #ffffff;
  }
  .resend{
    margin-top: 1rem;
    span{
      color: #208143;
      cursor: pointer;
      
    }
  }
}

.input {
  height: 40px;
  border: 1px solid  rgb(224, 224, 224);
  border-radius: 5px;
  width: 90%;
  margin: 1rem auto;
  outline: none;
}

.input:focus{
 outline-color: #208143;
}


.PhoneInputInput {
  height: 40px;
  border: 1px solid  rgb(224, 224, 224);
  border-radius: 5px;
  width: 100%;
}

//   .PhoneInputInput:focus {
//     outline-color: #208143;
//     border: none;
//   }

.PhoneInputCountry {
  height: 40px;
  display: flex;
  justify-content: center;
  border: 1px solid  rgb(224, 224, 224);
  border-radius: 5px;
  width: 100px;
}



.PhoneInputCountrySelect {
  height: 40px;
  display: flex;
  justify-content: center;
  // border: 1px solid  rgb(224, 224, 224);
  border-radius: 5px;
  width: 100px;
}

.PhoneInputCountryIcon--borde {
  border: none;
 }

.PhoneInputCountryIconImg{
  height: 14px;
  display: flex;
  justify-content: center;
  // border: 1px solid  rgb(224, 224, 224);
  width: 100px;
  margin-top: 1.1px;
  margin-left: 1px;
}
  
.input::placeholder {
  margin-left: 2rem;
  padding-left: 1rem;
  text-transform: capitalize;
}

.register-btn{
  background-color: #208143;
  color: white;
  text-transform: capitalize;
  width: 40%;
  margin-top: 2rem;
}
